<template>
  <v-card>
      <v-card-title class="primary--text text-h6">เลือกวิธีบันทึกข้อมูล<v-spacer/><v-btn icon color="primary" @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-row>
              <v-col cols="12">
                <MethodCard :title="'บันทึกข้อมูลของท่าน'" filename="keyinput.svg" @onClick="gotoInfo"/>
             </v-col>
              <v-col cols="12">
                <MethodCard v-if="isOnline" :title="'บันทึกข้อมูลของบุคคลอื่น'" filename="scaninput.svg" @onClick="gotoCamera"/>
             </v-col>
        </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
import MethodCard from "@/components/formlist/MethodCard"
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    form: {
      type: Object
    },
  },
  components: {
        MethodCard,
  },
  computed: {
    ...mapFields(["citizenObj"]),
  },
  methods: {
    gotoCamera() {
      this.$offlineStorage.set('currentForm',this.form)
      this.citizenObj = {}
      this.$router.push({ name: 'onboarding' })
    },
    gotoInfo(){
       this.$offlineStorage.set('currentForm',this.form)
       this.citizenObj = {
         id_number: this.$auth.role.cid,
         name_th: this.$auth.role.fullname,
         address_th: ''
       }
       this.$router.push({ name: 'inputform' })
    }
  },
}
</script>
<style lang="scss" scoped>

</style>