<template>
  <v-app-bar app color="primary" elevation="0">
    <v-app-bar-nav-icon>
      <template slot="default">
        <v-btn icon color="natural" :to="{ name: 'main' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
      </template>
    </v-app-bar-nav-icon>
    <v-spacer />

    <v-text-field
      class="pt-7"
      dense
      solo
      rounded
      flat
      placeholder="พิมพ์คำค้นหา"
      append-icon="mdi-magnify"
      v-bind:value="value"
      v-on="inputListeners"
    ></v-text-field>

    <v-btn icon :to="{ name: 'savedraft' }">
      <v-badge
        color="secondary"
        dot
        overlap
        bordered
        v-if="numberOfdraft > 0"
        offset-x="11"
        offset-y="10"
      >
        <v-icon color="natural">mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else color="natural">mdi-bell</v-icon>
    </v-btn>

    <template v-slot:extension>
      <h4 class="text-title-1 natural--text text-no-wrap">{{ title }}</h4>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "หัวข้อแสดงผล H4",
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      numberOfdraft: 0,
      role: null,
    };
  },
  methods: {
    fetchData() {
      this.role = this.$offlineStorage.get("role");
      let draft = this.$offlineStorage.get("draft");
      if (draft) {
        this.numberOfdraft = draft
          .filter((item) => item[0] === this.role.uuid.mobile)
          .map((item) => item[1]).length;
      }
    },
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-application .rounded-br-xl {
  border-bottom-right-radius: 24px !important;
}
</style>